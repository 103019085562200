.med-header {
    margin-left: -5rem;
    background-color: #273043;
}

.contact-image {
    background-image: url("../../imgs/interior-contact.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: none;
}

input {
    font-weight: light;
}

.estimates-submit {
    background-color: #7c877a;
}