.top-section-img {
    background-image: url("../../imgs/interior.jpeg");
    background-size: cover;
    background-repeat: none;
    background-position: center;
}

.top-section-img-2 {
    background-image: url("../../imgs/house.jpeg");
    background-size: cover;
    background-repeat: none;
    background-position: center; 
}

.top-section, .portfolio-section, .med-header-about {
    background-color: #273043;
}

.second-section-img {
    background-image: url("../../imgs/bathroom.webp");
    background-size: cover;
    background-repeat: none;
    background-position: center; 
}

.card-img-1 {
    background-image: url("../../imgs/bathroom2.jpeg");
    background-size: cover;
    background-repeat: none;
    background-position: center;   
}

.card-img-2 {
    background-image: url("../../imgs/kitchen.jpeg");
    background-size: cover;
    background-repeat: none;
    background-position: center;   
}

.card-img-3 {
    background-image: url("../../imgs/deck2.jpg");
    background-size: cover;
    background-repeat: none;
    background-position: center;   
}

.card-img-4 {
    background-image: url("../../imgs/bedroom.jpeg");
    background-size: cover;
    background-repeat: none;
    background-position: center;   
}

.med-header-about {
    margin-bottom: -2px;
}


@media only screen and (min-width: 670px) and (orientation: landscape) {
.first-card {
    margin-top: -50px;
}

.second-card {
    margin-top: -170px;
}

.third-card {
    margin-top: -90px;
}

.about-portfolio-btn {
    margin-top: -110px !important;
}
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
    .first-card {
        margin-top: -50px;
    }
    
    .second-card {
        margin-top: -170px;
    }
    
    .third-card {
        margin-top: -90px;
    }
    
    .about-portfolio-btn {
        margin-top: -110px !important;
    }
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        .first-card {
            margin-top: -50px;
            padding-right: 20px;
        }
        
        .second-card {
            margin-top: 20px;
            padding-right: 40px;
        }
        
        .third-card {
            margin-top: -130px;
        }
        
        .about-portfolio-btn {
            margin-top: 0px !important;
        }

        }


        @media only screen and (min-width: 1024px) and (orientation: portrait) {
            .first-card {
                margin-top: -50px;
                padding-right: 20px;
            }
            
            .second-card {
                margin-top: 20px;
                padding-right: 40px;
            }
            
            .third-card {
                margin-top: -130px;
            }
            
            .about-portfolio-btn {
                margin-top: 0px !important;
            }
    
            }