.top-section-img-ex {
    background-image: url("../../imgs/exterior-page-1.webp");
    background-position: center;
    background-repeat: none;
    background-size: cover;
}


.remodel-img-ex {
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    background-image: url("../../imgs/exterior-page-2.jpeg");
    background-position: center;
    background-size: cover;
    position: relative;
}

.card-img-1-ex {
    background-image: url("../../imgs/roofing-1.jpeg");
    background-size: cover;
    background-position: center;
}

.card-img-2-ex {
    background-image: url("../../imgs/roofing-2.jpeg");
    background-size: cover;
    background-position: center;
}

.card-img-3-ex {
    background-image: url("../../imgs/siding-1.jpeg");
    background-size: cover;
    background-position: center;
}

.card-img-4-ex {
    background-image: url("../../imgs/window-1.jpeg");
    background-size: cover;
    background-position: center;
}