.item {
    font-family: "Oswald", sans-serif;
    color:#F2F7F2;
}

.nav-menu {
    background-color: #273043;
}

.menu-image {
    background-image: url("../../imgs/menu-img.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: none;
}


.active-tab {
    color:#97a894;
}
