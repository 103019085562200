
.top-section-img-by {
    background-image: url("../../imgs/backyard-page-1.jpeg");
    background-position: center;
    background-repeat: none;
    background-size: cover;
}

.second-section-img-back {
    background-image: url("../../imgs/backyard-resto.jpeg");
    background-position: center;
    background-repeat: none;
    background-size: cover;
}


.remodel-img-by {
    background-image: url("../../imgs/backyard-page-2.jpeg");
    background-position: center;
    background-size: cover;
    position: relative;
}


.card-img-1-by {
    background-image: url("../../imgs/backyard-1.jpeg");
    background-size: cover;
    background-position: center;
}


.card-img-2-by {
    background-image: url("../../imgs/backyard-2.webp");
    background-size: cover;
    background-position: center;
}



.card-img-3-by {
    background-image: url("../../imgs/backyard-3.jpeg");
    background-size: cover;
    background-position: center;
}



.card-img-4-by {
    background-image: url("../../imgs/backyard-4.jpeg");
    background-size: cover;
    background-position: center;
}
