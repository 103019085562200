
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  overflow-y: scroll;
  height: 100svh;
  width: 100svw;
  position: relative;
  overflow-x: hidden; 
  background-color: #273043;;
}
.page {
  background-color: #273043;;
  width: 100svw !important;
  min-height: 100svh;
  margin: auto;
}

.whole {
  background-color: pink;
  min-width: 100svw;
  max-width: 100svw;
}

.text-light {
  color: #F2F7F2 !important;
}

.text-dark {
  color: #273043;
}

.pre-text-dark {
  color: #92a18f;
}

.pre-text-light {
  color: #7c877a;
}

.icon {
  background-color: #7c877a;
}

.sans-font-it {
  font-family: "Outfit", sans-serif;
  font-style: italic;
}

.sans-font {
  font-family: "Outfit", sans-serif;
  font-style: normal;
}
