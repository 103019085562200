.get-started-btn {
    font-size: .5rem;
}

.social {
    background-color: #F2F7F2;;
}

/* nav-lg {
    max-width: 1200px !important;
    margin: auto;
} */