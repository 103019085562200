header {
  min-height: 400px;
  min-width: 100%;
  color: white;
}





.left {
  background-image: url(../../imgs/header-image.jpeg);
  background-size: cover;
  background-repeat: none;
  min-height: 450px;
}

.right {
  background-color: #273043;
  min-height: 450px;
  font-family: "Oswald", sans-serif;
}

h2 {
    font-family: "Oswald", sans-serif;
}

.pre-text {
    color: #869583;

}

@media only screen and (min-width: 600px) {
    .header-text {
        font-size: .5rem;
    }
}
.header-text {
    color: #97a894;
    font-family: "Outfit", sans-serif;
}

.whole-dark {
    background-color: #273043;
    color: white;
}
.whole-lite {
    background-color: rgb(255, 255, 255);
}

.whole {
    padding: 5svw
}

.carousel-img {
    min-height: 350px;
    background-color: purple;
    background-position: center;
}

.service-circle {
    min-height: 80px;
    min-width: 80px;
    max-height: 80px;
    max-width: 80px;
}

.exterior {
    background-image: url("../../imgs/exterior1.jpeg");
    background-size: cover;
    background-repeat: none;
}

.interior {
    background-image: url("../../imgs/interior1.webp");
    background-size: cover;
    background-repeat: none;
}

.patios {
    background-image: url("../../imgs/deck1.webp");
    background-size: cover;
    background-repeat: none;
}

.backyard {
    background-image: url("../../imgs/hardscape1.jpeg");
    background-size: cover;
    background-repeat: none;
}

.fencing {
    background-image: url("../../imgs/fencing-page-2.jpeg");
    background-size: cover;
    background-repeat: none;
}

.remodel-img {
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    background-image: url("../../imgs/interior2.avif");
    background-position: center;
    background-size: cover;
    z-index: 10;
    position: relative;
}

.img {
    z-index: 0;
}


.service-circle {
    background-position: center;
    background-size: cover;
    background-repeat: none;
}

.circle-i {
    background-image: url("../../imgs/interior-filter-2.jpeg");
}

.circle-e {
    background-image: url("../../imgs/exterior-filter-2.jpeg");
}

.circle-b {
    background-image: url("../../imgs/backyard-filter-2.webp");
}

.circle-p {
    background-image: url("../../imgs/patio-filter-1.jpeg");
}

.circle-f {
    background-image: url("../../imgs/fencing-filter-2.jpeg");
}