footer {
    background-color: #273043;
    color: white;
    max-width: 1200px;
    min-width: 100svw;
    max-width: 100svw;
}


.logo {
    background-image: url("../../imgs/logo.png");
}

@media (min-width: 1024px) {
    .lower {
        display: fixed !important;
        bottom: 0;
        left: 0 !important;
        min-width: 100svw;
        max-width: 100svw;

    }
    

}
