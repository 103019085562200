.med-header-interior {
    background-color: #273043;
    margin-bottom: -1px;
}

.top-section-img-2-int {
    background-image: url("../../imgs/interior-pge-2.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.second-section-img-fence {
    background-image: url("../../imgs/fence-page-2.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}